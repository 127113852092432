import React, { useEffect, useRef } from "react";
import OktaSignIn from "@okta/okta-signin-widget";
import "@okta/okta-signin-widget/css/okta-sign-in.min.css";
import config from "../../config";
import logo from "../../logo.png";
import coxautologo from "../../coxautologo.png";
import { Box, Typography } from "@mui/material";

const OktaSignInWidget = ({ onSuccess, onError }) => {
  const widgetRef = useRef();
  useEffect(() => {
    console.log("inside useEffect of OktaSignInWidget");
    if (!widgetRef.current) {
      return false;
    }

    const { issuer, clientId, redirectUri, scopes, useInteractionCodeFlow, useClassicEngine } = config.oidc;

    // Search for URL Parameters to see if a user is being routed to the application to recover password
    var searchParams = new URL(window.location.href).searchParams;
    const otp = searchParams.get("otp");
    const state = searchParams.get("state");
    const oktaconfig = {
      /**
       * Note: when using the Sign-In Widget for an OIDC flow, it still
       * needs to be configured with the base URL for your Okta Org. Here
       * we derive it from the given issuer for convenience.
       */
      baseUrl: issuer.split("/oauth2")[0],
      clientId,
      redirectUri,
      logo: coxautologo,
      i18n: {
        en: {
          "primaryauth.title": "Sign in to MACT",
        },
      },
      authParams: {
        // To avoid redirect do not set "pkce" or "display" here. OKTA-335945
        issuer,
        scopes,
      },
      useClassicEngine,
      useInteractionCodeFlow, // Set to true, if your org is OIE enabled
      state,
      otp,
    };

    console.log(oktaconfig);

    const widget = new OktaSignIn(oktaconfig);

    widget
      .showSignInToGetTokens({
        el: widgetRef.current,
      })
      .then(onSuccess)
      .catch(onError);

    return () => widget.remove();
  }, [onSuccess, onError]);

  return (
    <>
      <Box sx={{ mt: 5 }}>
        <Typography variant="h1" component="h1" align="center">
          👋 Howdy!
        </Typography>

        <Typography variant="h4" component="h4" align="center" sx={{ mt: 2 }}>
          If you are unable to login and/or getting the error "User is not assigned to the client application, please
          contact support."
        </Typography>

        <Typography variant="h5" component="h5" color={"darkgray"} align="center" sx={{ mt: 2, mb: 2 }}>
          Contact Raju.Sagiraju@coxautoinc.com
        </Typography>
      </Box>
      <div className="loginpage" ref={widgetRef} />
    </>
  );
};

export default OktaSignInWidget;
